<template>
  <div>
    <div class="flex items-center">
      <label class="flex cursor-pointer items-center">
        <input
          ref="checkboxRef"
          :disabled="props.disabled"
          :checked="isChecked"
          class="peer hidden"
          :name="props.name"
          type="checkbox"
          @change="onChangeEvent"
          @blur="onBlurEvent"
        />
        <i
          :class="[
            { 'cursor-not-allowed opacity-20': props.disabled },
            'border-grays-light peer-checked:bg-teal-primary peer-checked:border-teal-primary inline-block h-[24px] w-[24px] rounded border bg-white peer-checked:relative peer-checked:before:absolute peer-checked:before:left-[50%] peer-checked:before:top-[25%] peer-checked:before:h-[12px] peer-checked:before:w-[7px] peer-checked:before:origin-center peer-checked:before:translate-x-[-50%] peer-checked:before:translate-y-[-30%] peer-checked:before:rotate-[40deg] peer-checked:before:border-b-[2px] peer-checked:before:border-r-[2px]',
          ]"
        />
        <div class="ml-xs text-n-600 text-[14px]">
          <slot />
        </div>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface ZnCheckboxProps {
  modelValue?: any
  value?: any
  disabled?: boolean
  name?: string
}

interface ZnCheckboxEvents {
  (e: 'update:modelValue', value: boolean | any[]): void
  (e: 'change', value: boolean | any[]): void
  (e: 'blur', value: boolean | any[]): void
}

const props = defineProps<ZnCheckboxProps>()
const emits = defineEmits<ZnCheckboxEvents>()

const isChecked = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(props.value)
  }
  return !!props.modelValue
})

const onChangeEvent = (event: Event) => {
  const el = event.target as HTMLInputElement

  if (Array.isArray(props.modelValue)) {
    const newArray = [...props.modelValue]
    const index = newArray.indexOf(props.value)

    if (el.checked && index === -1) {
      newArray.push(props.value)
    } else if (!el.checked && index !== -1) {
      newArray.splice(index, 1)
    }

    emits('update:modelValue', newArray)
    emits('change', newArray)
  } else {
    emits('update:modelValue', el.checked)
    emits('change', el.checked)
  }
}

const onBlurEvent = (event: Event) => {
  const el = event.target as HTMLInputElement

  if (Array.isArray(props.modelValue)) {
    emits('blur', props.modelValue)
  } else {
    emits('blur', el.checked)
  }
}
</script>
